<template>
  <b-overlay :show="formShow" rounded="sm" no-fade>
    <!-- Stats Card Vertical -->
    <b-row class="match-height">
      <b-col xl="3" md="4">
        <statistic-card-vertical icon="UsersIcon" :statistic="totalCompanyScore" statistic-title="Total Company Score" color="primary" />
      </b-col>
      <b-col xl="3" md="4" sm="6">
        <statistic-card-vertical color="info" icon="ActivityIcon" :statistic="companyBenchmark" statistic-title="Company Benchmark" />
      </b-col>
      <b-col xl="3" md="4" sm="6">
        <statistic-card-vertical color="success" icon="UserCheckIcon" :statistic="averageScore" statistic-title="Mean / Average Score" />
      </b-col>
      <b-col xl="3" md="4" sm="6">
        <statistic-card-vertical color="danger" icon="UserXIcon" :statistic="standardDeviation" statistic-title="Standard Deviation" />
      </b-col>
    </b-row>

    <b-row class="match-height" v-if="true">
      <b-col xl="12">
        <b-card>
          <template>
            <chartjs-component-bar-chart :height="400" :data.sync="data" v-if="data.datasets[0].data.length > 0" :options="options" />
          </template>
        </b-card>
      </b-col>
    </b-row>

    <b-row v-if="true">
      <b-col xl="12">
        <b-card>
          <apexchart type="pie" width="380" :options="chartOptions" :series="series"></apexchart>
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col xl="12">
        <b-card>
          <apexchart type="bar" height="480" :options="chartOptions2" :series="series2"></apexchart>
        </b-card>
      </b-col>
    </b-row>
  </b-overlay>
</template>

<script>
import { BRow, BCol, BMedia, BMediaAside, BFormCheckbox, BButton } from 'bootstrap-vue';
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue';
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue';
import StatisticCardWithAreaChart from '@core/components/statistics-cards/StatisticCardWithAreaChart.vue';
import StatisticCardWithLineChart from '@core/components/statistics-cards/StatisticCardWithLineChart.vue';
import { kFormatter } from '@core/utils/filter';
import FormStatisticOrderChart from './FormStatisticOrderChart.vue';
import FormStatisticProfitChart from './FormStatisticProfitChart.vue';
import FormStatisticsGroup from './FormStatisticsGroup.vue';
import { VueGoodTable } from 'vue-good-table';
import formStoreModule from './formStoreModule';
import store from '@/store';
import { ref, onUnmounted, reactive } from '@vue/composition-api';
import router from '@/router';
import { create, all } from 'mathjs';
import ChartjsComponentBarChart from './charts-components/ChartjsComponentBarChart.vue';
import { $themeColors } from '@themeConfig';

export default {
  components: {
    BRow,
    BCol,
    BMedia,
    BMediaAside,
    BFormCheckbox,
    BButton,
    StatisticCardVertical,
    StatisticCardHorizontal,
    StatisticCardWithAreaChart,
    StatisticCardWithLineChart,
    FormStatisticOrderChart,
    FormStatisticProfitChart,
    FormStatisticsGroup,
    VueGoodTable,
    ChartjsComponentBarChart,
  },
  data() {
    const config = {};
    const math = create(all, config);

    const chartColors = {
      primaryColorShade: '#836AF9',
      yellowColor: '#ffe800',
      successColorShade: '#28dac6',
      warningColorShade: '#ffe802',
      warningLightColor: '#FDAC34',
      infoColorShade: '#299AFF',
      greyColor: '#4F5D70',
      blueColor: '#2c9aff',
      blueLightColor: '#84D0FF',
      greyLightColor: '#EDF1F4',
      tooltipShadow: 'rgba(0, 0, 0, 0.25)',
      lineChartPrimary: '#666ee8',
      lineChartDanger: '#ff4961',
      labelColor: '#6e6b7b',
      blackColor: '#000',
      grid_line_color: 'rgba(200, 200, 200, 0.2)',
    };

    return {
      //Simple Pi Data
      series: [44, 55],
      chartOptions: {
        chart: {
          width: 380,
          type: 'pie',
        },
        labels: ['YES - 1', 'NO - 1'],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: 'bottom',
              },
            },
          },
        ],
      },

      //Simple Data pi Finish

      //Bar Chart Start

      series2: [
        {
          data: [400, 430, 448, 470, 540, 580, 690, 1100],
        },
      ],
      chartOptions2: {
        chart: {
          type: 'bar',
          height: 380,
        },
        plotOptions: {
          bar: {
            barHeight: '100%',
            distributed: true,
            horizontal: false,
            dataLabels: {
              position: 'bottom',
            },
          },
        },
        colors: ['#33b2df', '#546E7A', '#d4526e', '#13d8aa', '#A5978B', '#2b908f', '#f9a3a4', '#90ee7e', '#f48024', '#69d2e7'],
        dataLabels: {
          enabled: false,
          textAnchor: 'start',
          style: {
            colors: ['#fff'],
          },
          formatter: function(val, opt) {
            return opt.w.globals.labels[opt.dataPointIndex] + ':  ' + val;
          },
          offsetX: 0,
          dropShadow: {
            enabled: true,
          },
        },
        stroke: {
          width: 0.5,
          colors: ['#000'],
        },
        xaxis: {
          labels: {
            show: false,
          },
          categories: ['Radio', 'Word of Mouth', 'Social Media', 'Newspaper', 'Community Newsletter', 'Community Presentation', 'Referral from another organisation', 'Other'],
        },
        yaxis: {
          labels: {
            show: false,
          },
        },
        title: {
          text: 'Custom DataLabels',
          align: 'center',
          floating: true,
        },
        subtitle: {
          text: 'Category Names as DataLabels inside bars',
          align: 'center',
        },
        tooltip: {
          theme: 'dark',
          x: {
            show: false,
          },
          y: {
            title: {
              formatter: function() {
                return '';
              },
            },
          },
        },
      },

      //Bar Chartg Finish

      math,
      formShow: false,
      fields: ['show_details', 'id', 'responder'],
      responses: [],
      statistics: {},
      sections: [],
      id: router.currentRoute.params.formId,
      totalCompanyScore: 0,
      companyBenchmark: 0,
      averageScore: 0,
      standardDeviation: 0,
      sectionTotal: 0,
      totalReference: 0,
      formElements: [],

      data: {
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: chartColors.blueColor,
            borderColor: chartColors.blackColor,
          },
        ],
      },
      options: {
        plugins: {
          autocolors: false,
          annotation: {
            annotations: {
              box1: {
                type: 'box',
                xMin: 1,
                xMax: 2,
                yMin: 50,
                yMax: 70,
                backgroundColor: 'rgba(255, 99, 132, 0.25)',
              },
            },
          },
        },

        elements: {
          rectangle: {
            borderWidth: 0.2,
            borderSkipped: 'bottom',
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        responsiveAnimationDuration: 500,
        legend: {
          display: false,
        },
        tooltips: {
          // Updated default tooltip UI
          shadowOffsetX: 1,
          shadowOffsetY: 1,
          shadowBlur: 8,
          shadowColor: chartColors.tooltipShadow,
          backgroundColor: $themeColors.light,
          titleFontColor: $themeColors.dark,
          bodyFontColor: $themeColors.dark,
        },
        scales: {
          xAxes: [
            {
              display: true,
              gridLines: {
                display: true,
                color: chartColors.grid_line_color,
                zeroLineColor: chartColors.grid_line_color,
              },
              scaleLabel: {
                display: false,
              },
              ticks: {
                fontColor: chartColors.labelColor,
              },
            },
          ],
          yAxes: [
            {
              display: true,
              gridLines: {
                color: chartColors.grid_line_color,
                zeroLineColor: chartColors.grid_line_color,
              },
              ticks: {
                stepSize: 20,
                min: 0,
                max: 100,
                fontColor: chartColors.labelColor,
              },
            },
          ],
        },
      },
    };
  },

  setup() {
    const FORM_APP_STORE_MODULE_NAME = 'form';
    // Register module
    if (!store.hasModule(FORM_APP_STORE_MODULE_NAME)) store.registerModule(FORM_APP_STORE_MODULE_NAME, formStoreModule);
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(FORM_APP_STORE_MODULE_NAME)) store.unregisterModule(FORM_APP_STORE_MODULE_NAME);
    });
  },

  created() {
    this.getFormList();
    this.getFormStatistics();
  },
  methods: {
    kFormatter,

    dataFormat: function(a, b) {
      if (b) return b + '%';
      return a;
    },

    getFormList() {
      const sectionFields = [];
      this.formShow = true;
      store.dispatch('form/getFormList', this.id).then((response) => {
        this.responses = response.data;
        var maxValue = 0;
        response.data.forEach((element) => {
          this.data.labels.push(element.responder);
          this.data.datasets[0].data.push(element.userGrandTotal);
          this.totalCompanyScore += element.userGrandTotal;

          if (element.userGrandTotal > 0) {
            this.formElements.push(element.userGrandTotal);
          }

          if (maxValue < element.userGrandTotal) {
            maxValue = element.userGrandTotal;
          }

          this.options.scales.yAxes[0].ticks.max = maxValue + (20 - (maxValue % 20)); //(this.totalCompanyScore / this.formElements.length) * 20;

          //64,57,56
          //  this.companyBenchmark :0,
          //  this.averageScore:0,
          //  this.standardDeviation:0,
        });

        this.standardDeviation = this.math.round(this.math.std(this.formElements), 9);

        this.getSections(response.data);

        this.formShow = false;
      });
    },
    getFormStatistics() {
      this.formShow = true;
      store.dispatch('form/getFormStatistics', this.id).then((response) => {
        this.statistics = response.data;
        this.averageScore = response.data.averageScore;
        //((this.totalCompanyScore % 20) / 2) * 20; //((this.math.floor() * 3) % 20) * 20;
        this.formShow = false;
      });
    },
    getSections(dataForm) {
      this.totalReference = dataForm.length;
      this.formShow = true;
      store.dispatch('form/getFormSections', this.id).then((response) => {
        response.data.forEach((element) => {
          this.fields.push(element.name);

          this.sectionTotal += Number(element.score);
        });

        this.companyBenchmark = this.totalReference * this.sectionTotal;

        this.fields.push('total');
        this.formShow = false;
      });
    },
  },
};
</script>
